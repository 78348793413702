<template>
  <div>
    <h1>{{ $t('views.instructions.h1_title') }}</h1>
    <p>{{ $t('views.instructions.p_instructions_1') }}</p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <div v-for="(row, i) in input" :key="`row${i}`">
      <v-row>
        <v-col cols="4" sm="3">
          <div v-for="(image, j) in row.images" :key="`image${j}`">
            <div v-if="!!j" class="mb-3 text-center">
              <fa-icon :icon="['fal', 'long-arrow-down']" size="lg" transform="grow-2 left-5" />
            </div>
            <div>
              <v-img class="mx-auto" max-width="80" position="center center" :src="getImage(image)" />
            </div>
          </div>
        </v-col>
        <v-col class="pa-0 pr-6 pt-2" cols="8" sm="9">
          <p v-for="(text, k) in row.text" :key="`text${k}`">
            {{ text }}
          </p>
        </v-col>
      </v-row>
      <v-divider class="grey accent-1 mt-3 mb-6" />
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import { webp } from '@/mixins'
import { settings } from '@/config'
import BtnHome from '@/components/btn/Home'

export default {
  name: 'Instructions',
  components: { Adslot, BtnHome },
  mixins: [webp],
  inject: ['theme'],
  data() {
    return {
      input: [
        {
          images: ['01', '02'],
          text: [this.$t('views.instructions.input.p_text_1'), this.$t('views.instructions.input.p_text_2')],
        },
        {
          images: ['05', '06'],
          text: [this.$t('views.instructions.input.p_text_3')],
        },
        {
          images: ['03', '04'],
          text: [this.$t('views.instructions.input.p_text_4')],
        },
        {
          images: ['07', '08'],
          text: [this.$t('views.instructions.input.p_text_5')],
        },
        {
          images: ['08', '09'],
          text: [this.$t('views.instructions.input.p_text_6')],
        },
        {
          images: ['14', '15', '06'],
          text: [this.$t('views.instructions.input.p_text_7'), this.$t('views.instructions.input.p_text_8')],
        },
      ],
    }
  },
  computed: {
    imgurl() {
      return settings.cdn.url
    },
    theme() {
      return 'light'
    },
  },
  methods: {
    getImage(val) {
      return this.webpExtension(`${this.imgurl}help-${val}-${!this.theme.isDark ? 'light' : 'dark'}.jpg`)
    },
  },
}
</script>

<style lang="scss"></style>
